import React, { useEffect, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import VehicleDetails from "../../SubComponents/VehicleDetails";
import HomePage from "../HomePage";
import BottomBox from "../../SubComponents/BottomBox";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllVehicle,
  getVehicleDetails,
} from "../../store/actions/TripsAction";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
import SvgImage from "../../Core/Icons";

//toast.configure()
function TruckRecord(props) {
  const [vrn, setVrn] = useState("");
  const { state } = useLocation();

  const [details, setDetails] = useState(state?.vrn || false);
  const dispatch = useDispatch();

  const { vehicleDetails, loader, allFastagVehcile } = useSelector(
    (state) => state?.trip
  );
  console.log("sejfoijer", allFastagVehcile);
  function GetDetails() {
    dispatch(
      getVehicleDetails({ vrn }, (resp) => {
        console.log("smekfjoer", resp);
        if (resp?.status == 200) {
          setDetails(true);
        }
      })
    );
  }
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllVehicle({}, (resp) => {}));
  }, []);

  return (
    <div className="">
      {details ? (
        <div className="">
          <Header />
          <div
            className="d-flex flex-column"
            style={{ margin: "50px 0 0 0", gap: "10px" }}
          >
            <div>
              <img
                src="https://truckcdn.cardekho.com/in/eicher/pro-3015/eicher-pro-3015-65791.jpg"
                alt="truck-img"
                className="truck-img"
              />
            </div>
            <div className="FontNB24">{vehicleDetails?.vrn}</div>
            <div className="Font12">{vehicleDetails?.makerModel}</div>

            <VehicleDetails vrn={vrn} />
            <BottomBox setDetails={setDetails} vrn={vrn} />
          </div>
        </div>
      ) : (
        <div className="TruckRecord">
          <Header />
          <div
            className="d-flex flex-column"
            style={{ margin: "50px 0 0 0", gap: "10px" }}
          >
            <div className="FontNB24">Track Vehicle Records</div>
            <div className="FontNS20">Enter Vehicle Number</div>
            <div>
              <input
                type="text"
                className="inputBoxAddCust"
                onChange={(e) => setVrn(e.target.value)}
              />
            </div>
            <div className="addCustcenter" style={{ margin: "30px 0 0 0" }}>
              <button
                className="greenButton Medium text3 AddCustStartBtn"
                style={{ padding: "0 20px 0 20px" }}
                // onClick={() => setSendOtp(1)}
              >
                <div
                  className="AddCust"
                  onClick={() => {
                    if (!loader && vrn) GetDetails();
                  }}
                >
                  {loader ? (
                    <ClipLoader
                      color={"#2E7C7B"}
                      loading={true}
                      size={20}
                      aria-label="Loading Spinner"
                      data-testid="loader"
                    />
                  ) : (
                    "Get Details"
                  )}
                </div>
              </button>
            </div>
          </div>

          <div className="txnDetalis" style={{ marginTop: "30px" }}>
            {allFastagVehcile?.length < 1 ? (
              <></>
            ) : (
              allFastagVehcile?.map((item, idx) => {
                let isData =
                  item?.permitUpto || item?.roadTaxUpto || item?.insuranceUpto;
                return (
                  <div
                    className=""
                    style={{
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                      cursor: "pointer",
                    }}
                    onClick={() => 
                    {
                      navigate(`/truckRecord/${item?.vrn}`, {
                        state: { vrn: item?.vrn },
                      });
                    }
                    }
                  >
                    <div
                      className=""
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        padding: "10px 0px 10px 10px",
                      }}
                    >
                      <div
                        className="fontNM18 d-flex flex-column"
                        style={{ gap: "6px" }}
                      >
                        <div style={{ textAlign: "left" }}>{item?.vrn}</div>

                        {!isData ? (
                          <div
                            className="d-flex options-truck-record"
                            style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                          >
                            <div>
                              <SvgImage name="alert" />
                            </div>
                            <div className="fontNM12">Records Not Fetched</div>
                          </div>
                        ) : (
                          <div className="d-flex" style={{ gap: "5px" }}>
                            {item?.insuranceUpto && (
                              <div
                                className="d-flex options-truck-record"
                                style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                              >
                                <div>
                                  <SvgImage name="tick" />
                                </div>
                                <div className="fontNM12">Insurance</div>
                              </div>
                            )}
                            {item?.permitUpto && (
                              <div
                                className="d-flex options-truck-record"
                                style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                              >
                                <div>
                                  <SvgImage name="tick" />
                                </div>
                                <div className="fontNM12">Permit</div>
                              </div>
                            )}

                            {item?.roadTaxUpto && (
                              <div
                                className="d-flex options-truck-record"
                                style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                              >
                                <div>
                                  <SvgImage name="tick" />
                                </div>
                                <div className="fontNM12">Road Tax</div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      {!isData && (
                        <div
                          className="addCustcenter"
                          style={{ margin: "10px" }}
                        >
                          <button
                            className="greenButtonGlobal Medium text3 AddCustStartBtn"
                            style={{ padding: "0 20px 0 20px" }}
                            // onClick={() => setSendOtp(1)}
                          >
                            <div
                              className="AddCust"
                              onClick={() => {
                                console.log("smkmrkfkr");
                                navigate(`/truckRecord/${item?.vrn}`, {
                                  state: { vrn: item?.vrn },
                                });
                              }}
                            >
                              {"Get Records"}
                            </div>
                          </button>
                        </div>
                      )}

                      {/* <div className="fastagBoxDesc">{item?.challan_date_time} || {item?.challan_place}</div> */}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
}
export default TruckRecord;
