import NetworkClient from "./NetworkClient";
import {
  BASE_URL,
  LOGIPE_BASE_URL,
  LOGIPE_LITE
} from "./globals";
import { URLS } from "./urls";
import { getDataFromAsyncStorage } from "./functions";
// import { ObjectSchema } from "yup";

export async function Apis() {
  return NetworkClient.get(`${LOGIPE_LITE}${URLS.sendOtp}`);
}

export async function sendOtpApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.sendOtp}`, obj);
}
export async function verifyOtpApi(obj) {
  console.log(obj);
  return NetworkClient.post(`${BASE_URL}${URLS.verifyOtp}`, {
    phone: obj.phone,
    otp: obj.otp,
  });
}
export async function getCitiesApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.get(`${LOGIPE_BASE_URL}${URLS.getCities}?lat=${
      obj?.lat || 0
    }&long=${obj?.long || ""}&search=${obj?.search || ""}`);
}

export async function tripForcastApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.tripForcast}`,obj);
}

export async function getVehicleDetailsApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getVehicleDetails}`,obj);
}
export async function getVehicleChallanApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getVehicleChallan}`,obj);
}
export async function getFastagTxnApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getFastagTxn}`,obj);
}
export async function sendOtpFastagApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.sendOtpFastag}`,obj);
}
export async function verifyOtpFastagApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.verifyOtpFastag}`,obj);
}
export async function getAllVehicleApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.getAllVehicle}`,obj);
}
export async function searchDriverApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.searchDriver}`,obj);
}
export async function searchDriverHistoryApi(obj) {
  console.log("Login Obj", obj);
  return NetworkClient.post(`${BASE_URL}${URLS.searchDriverHistory}`,obj);
}