import React from "react";
import SvgImage from "../../Core/Icons";
import { useNavigate } from "react-router-dom";

function BottomBox(props) {
  const {setDetails,vrn} = props;
  const navigate = useNavigate()

  return (
    <div>
      <button className="purpleButton-2" onClick={() => navigate("/OtherTruckRecordOptions",{state : {vrn}})}>Check Another Vehicle</button>

      <div class="d-flex justify-content-evenly ">
        <div className="col-lg-5 btm-box">
          <div className="accountingSvg">
            <SvgImage
              name="accounting1"
              width={48}
              height={48}
              viewBox="0 0 48 48"
              style={{ alignSelf: "center", marginTop: "6px" }}
            />
          </div>
          <p style={{ margin: "3px", fontSize: "12px", fontWeight: "500" }}>
            Calculate<br></br> Trip Expense
          </p>
        </div>
        <div className="col-lg-5 btm-box">
          <div className="accountingSvg">
            <SvgImage
              name="driverIcon"
              width={48}
              height={48}
              viewBox="0 0 48 48"
              style={{ alignSelf: "center", marginTop: "6px" }}
            />
          </div>
          <p
            style={{
              margin: "3px 0 3px 0",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Get Experienced <br></br> Drivers
          </p>
        </div>
      </div>
      <button className="purpleButton-1">
        Manage your Fleet with Logipe. Download App
      </button>
    </div>
  );
}

export default BottomBox;
