import React, { useEffect, useState } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import PinInput from "react-pin-input";
import VehicleDetails from "../../SubComponents/VehicleDetails";
import HomePage from "../HomePage";
import BottomBox from "../../SubComponents/BottomBox";
import { useDispatch, useSelector } from "react-redux";
import { getAllVehicle, getVehicleDetails } from "../../store/actions/TripsAction";
import { ClipLoader } from "react-spinners";
import { useLocation, useNavigate, useParams } from "react-router-dom";

//toast.configure()
function TruckRecordVehicle(props) {
  const [vrn, setVrn] = useState("");
  const { state } = useLocation();

  const [details, setDetails] = useState(state?.vrn || false);
  const dispatch = useDispatch();

  const {vehicleDetails,loader,allFastagVehcile} = useSelector(state => state?.trip)
  console.log("sejfoijer",allFastagVehcile)

  let { id } = useParams();


  useEffect(() => {
    if(id){
      dispatch(
        getVehicleDetails(
          { vrn: id },
          (resp) => {
            console.log("smekfjoer", resp);
            if (resp?.status == 200) {
  
            }
          }
        )
      );
    }
  },[])

  return (
    <div className="">
        <div className="">
          <Header />
          <div
            className="d-flex flex-column"
            style={{ margin: "50px 0 0 0", gap: "10px" }}
          >
            <div>
              {vehicleDetails?.imageUrl && <img
                src={`https://storage.googleapis.com/logipe-public/${vehicleDetails?.imageUrl}`}
                alt="truck-img"
                className=""
              />}
            </div>
            <div className="FontNB24">{vehicleDetails?.vrn}</div>
            <div className="Font12">{vehicleDetails?.makerModel}</div>

            <VehicleDetails vrn={id} />
            <BottomBox setDetails={setDetails} vrn={id}/>
          </div>
        </div>
    </div>
  );
}
export default TruckRecordVehicle;
