import React, { memo, useEffect, useState } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import SendOtp from "./Components/SendOtp";
import "./MainApp.css";
import ProctedRout from "./protectedHoc";
import TruckRecord from "./Components/TruckRecord";
import HomePage from "./Components/HomePage";
import CheckFASTagTrnxs from "./Components/CheckFASTagTrnxs";
import PendingChallans from "./Components/PendingChallans";
import ExpenseCalculator from "./Components/ExpenseCalculator";
import OtherTruckRecordOptions from "./Components/OtherTruckRecordOptions";
import CheckMoreVehicles from "./Components/CheckMoreVehicles";
import AllFastagVehicles from "./Components/AllFastagVehicles";
import TruckRecordVehicle from "./Components/TruckRecordVehicle";
import SearchDrivers from "./Components/SearchDriver";
import DriverSearchList from "./Components/SearchList";
import SearchHistoryList from "./Components/SearchList";

//toast.configure()
function MainApp(props) {
  const { location, deviceToken, isTokenFound } = props;

  let user = sessionStorage.getItem("_user");
  console.log("ijsdrijr", user);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("msoejfojwe", position);
        // setPosition({
        //   latitude: position.coords.latitude,
        //   longitude: position.coords.longitude,
        // });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  return (
    <div
      className="mobileView"
      // style={{ overflowY: "hidden", overflowX: "hidden" }}
      style={{ padding: 0, height: "100vh", overflowY: "scroll" }}
    >
      {/* {authUser ? (
        <>
          <Header accountName="" accountTitle="" companyName="" />
          <SideBar
            setSideBar={setSideBar}
            sideBar={sideBar}
            setAct2={setAct2}
            act2={act2}
            authUser={JSON.parse(user)}
          ></SideBar>
        </>
      ) : null} */}
      <Routes location={location}>
        <Route
          exact
          path="/"
          element={
            <ProctedRout
              component={<SendOtp />}
              // user={user}
            />
          }
        />
        <Route
          exact
          path="/login"
          element={
            // <ProctedRout
              <SendOtp />
              // user={user}
            // />
          }
        />
        <Route
          path="truckRecord"
          element={
            <ProctedRout
              component={<TruckRecord />}
              // user={user}
            />
          }
        />
        <Route
          path="truckRecord/:id"
          element={
            <ProctedRout
              component={<TruckRecordVehicle />}
              // user={user}
            />
          }
        />
        <Route
          path="CheckFASTagTrnxs"
          element={
            <ProctedRout
              component={<CheckFASTagTrnxs />}
              // user={user}
            />
          }
        />
        <Route
          path="PendingChallans"
          element={
            <ProctedRout
              component={<PendingChallans />}
              // user={user}
            />
          }
        />
        <Route
          path="OtherTruckRecordOptions"
          element={
            <ProctedRout
              component={<OtherTruckRecordOptions />}
              // user={user}
            />
          }
        />
        <Route
          path="CheckMoreVehicles"
          element={
            <ProctedRout
              component={<CheckMoreVehicles />}
              // user={user}
            />
          }
        />
        <Route
          path="allFastagVehicles"
          element={
            <ProctedRout
              component={<AllFastagVehicles />}
              pathName={window.location.pathname}
            />
          }
        />
        <Route
          path="ExpenseCalculator"
          element={
            <ProctedRout
              component={<ExpenseCalculator />}
              pathName={window.location.pathname}
            />
          }
        />

<Route
        path="SearchDrivers"
        element={
          <ProctedRout
            component={<SearchDrivers />}
            pathName={window.location.pathname}
          />
        }
        />

    <Route
        path="driverSearchList"
        element={
          <ProctedRout
            component={<DriverSearchList />}
            pathName={window.location.pathname}
          />
        }
        />

    <Route
        path="driverSearchHistory"
        element={
          <ProctedRout
            component={<SearchHistoryList />}
            pathName={window.location.pathname}
          />
        }
        />
      </Routes>
    </div>
  );
}
export default memo(MainApp);
