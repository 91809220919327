import {SEARCH_DRIVER,SEARCH_DRIVER_SUCCESS,  SEARCH_DRIVER_HISTORY_SUCCESS,
  SEARCH_DRIVER_HISTORY,
  CLEAN_SEARCH_DRIVER_HISTORY } from "../storeTypes";


const INIT_STATE = {
  loader: false,  
  driverSearchList: [],
  driverSearchHistoryList : {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SEARCH_DRIVER: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case SEARCH_DRIVER_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        driverSearchList:action?.payload?.data
      };
    }

    case SEARCH_DRIVER_HISTORY: {
      return {
        ...state,
        loader: true,
      };
    }
    case SEARCH_DRIVER_HISTORY_SUCCESS: {
      return {
        ...state,
        loader: false,
        driverSearchHistoryList : action?.payload?.data
      };
    }


    case CLEAN_SEARCH_DRIVER_HISTORY: {
      return {
        ...state,
        driverSearchHistoryList: {},
      };
    }

    default: {
      return state;
    }
  }
};
