
export const URLS = {
  sendOtp: "login/send/otp",
  verifyOtp: "login/verify/otp",
  getCities: "utility/getCities",
  tripForcast: "trip/forcast",
  getVehicleDetails: "vehicle/get/vehicle",
  getVehicleChallan: "vehicle/get/challan",
  getFastagTxn: "vehicle/get/fastag",
  sendOtpFastag: "vehicle/get/idfc/vehicles/otp",
  verifyOtpFastag: "vehicle/verify/idfc/vehicles/otp",
  getAllVehicle: "vehicle/get/vehicles",
  searchDriver: "searchDriver/drivers",
  searchDriverHistory: "searchDriver/history",


};
