import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProctedRout = (props) => {

  let user = JSON.parse(sessionStorage.getItem("_user"))
  if (user?.token && user?.phone ) {
    if(window.location.pathname == "/" || window.location.pathname == "/login") {
      console.log("kmksnkr",window.location.pathname == "/")
      return <Navigate to="/truckRecord"/>
    }
    else return props.component;
  } else {
    return <Navigate to="/login" state={{ pathName : props.pathName }}/>;
  }
};

export default ProctedRout;
