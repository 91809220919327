import React, { useEffect } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleChallan } from "../../store/actions/TripsAction";
import { useLocation } from "react-router-dom";
import SvgImage from "../../Core/Icons";
import { ClipLoader } from "react-spinners";

function SearchHistoryList(props) {
  const { vrn } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { driverSearchList } = useSelector((state) => state?.trip);
  console.log("jsrfjr", driverSearchList);

    // useEffect(() => {
    //   console.log("sjrofojrs");
    //     dispatch(sea({ vrn: state?.vrn }, (resp) => {}));
    // }, []);

//   let driverSearchList = [
//     {
//       name: "12",
//       dlNo: "KA36 20120013331",
//       vehicleClass: [
//         {
//           cat: "NT",
//           date: "2012-12-11",
//           class: "LMV",
//         },
//       ],
//       psvBadges: null,
//       image: "21d3e1c9-cb86-42fc-b2de-060c1c254ab3-image.jpeg",
//       validityDetails: [
//         {
//           type: "Non Transport",
//           issueDate: "2012-12-11",
//           validTill: "2032-12-10",
//         },
//         {
//           type: "Transport",
//           issueDate: null,
//           validTill: null,
//         },
//         {
//           type: "Hazardous",
//           issueDate: null,
//           validTill: null,
//         },
//         {
//           type: "Hill",
//           issueDate: null,
//           validTill: null,
//         },
//       ],
//       latitude: 12.9543,
//       longitude: 77.4661,
//       distance: 9.468290435712882,
//       driverBid: null,
//     },
//     {
//       name: "13",
//       dlNo: "dl333",
//       vehicleClass: null,
//       psvBadges: null,
//       image: null,
//       validityDetails: null,
//       latitude: 12.9543,
//       longitude: 77.4661,
//       distance: 9.468290435712882,
//       driverBid: null,
//     },
//     {
//       name: "11",
//       dlNo: "RJ01 19970116557",
//       vehicleClass: [
//         {
//           cat: "NT",
//           date: "1997-12-12",
//           class: "LMV",
//         },
//         {
//           cat: "TR",
//           date: "2000-01-23",
//           class: "TRANS",
//         },
//       ],
//       psvBadges: [
//         {
//           date: null,
//           class: "TRANS",
//         },
//       ],
//       image: "efc33e99-1251-4ac6-a156-d8be9b5898fe-image.jpeg",
//       validityDetails: [
//         {
//           type: "Non Transport",
//           issueDate: "2019-03-14",
//           validTill: "2028-05-02",
//         },
//         {
//           type: "Transport",
//           issueDate: "2022-03-11",
//           validTill: "2027-03-10",
//         },
//         {
//           type: "Hazardous",
//           issueDate: null,
//           validTill: null,
//         },
//         {
//           type: "Hill",
//           issueDate: null,
//           validTill: null,
//         },
//       ],
//       latitude: 12.9543,
//       longitude: 77.4661,
//       distance: 9.468405115337577,
//       driverBid: null,
//     },
//   ];

  // vehicleChallan?.data?.response?.[0]?.response?.data?.Pending_data

  // Disposed_data
  return (
    <div className="">
      <Header />
      {/* 
      {driverSearchList?.data == false ? <div>OOPS... SERVER DOWN</div> : !driverSearchList?.data?.response ? (
        <div>LOADING....</div>
      ) : ( */}
      <>
        {" "}
        <div
          className="d-flex flex-column"
          style={{ margin: "10px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">{state?.vrn}</div>
          <div className="FontNB24">
            {driverSearchList?.length || 0} Drivers Found!
          </div>
        </div>
        <div className="fontNS16" style={{color : "rgba(108, 108, 108, 1)"}}>Your Request is sent to Drivers</div>
        <div className="fontNS16" style={{color : "rgba(108, 108, 108, 1)"}}>We will let you know once they accept it</div>
        <div className="fontNS16" style={{color : "rgba(108, 108, 108, 1)"}}>or</div>
        <div className="fontNS16" style={{color : "rgba(108, 108, 108, 1)"}}>Pick Them here for us to connect</div>

        {driverSearchList?.length > 0 && <div className="addCustcenter" style={{textAlign : "right",margin : "10px 10px 0 0"}}>
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={toggleCreateCust}
            >
              <div className="AddCust">{false ?                        <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Accept All Requests"}</div>
            </button>
          </div>}
        <div className="d-flex flex-column" style={{    gap: "60px",
    padding: "10px 0 20px 0"
}}>
        <div className="txnDetalis d-flex flex-column" style={{ marginTop: "10px",gap : "10px" }}>
          {driverSearchList &&
            driverSearchList?.map((item, idx) => {
              return (
                <div className="d-flex flex-column">
                  <div
                    className="d-flex"
                    style={{
                      justifyContent: "space-between",
                      gap: "20px",
                      border: "1px solid rgba(108, 108, 108, 0.24)",
                    borderBottom: "none"
                    }}
                  >
                    <div
                      style={{ width: "30%", padding: "15px" }}
                      className="d-flex flex-column"
                    >
                      <div>
                        <img
                          src={`/assets/images/image.png`}
                          style={{ width: "80px" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{ width: "70%",gap : "8px" }}
                      className="d-flex flex-column"
                    >
                      <div
                        className="d-flex"
                        style={{
                          width: "70%",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          className="d-flex flex-column"
                          style={{ width: "70%", textAlign: "left" }}
                        >
                          <div>Ram Singh</div>
                          <div>Ram Singh</div>
                          <div>Ram Singh</div>
                        </div>
                        <div style={{ width: "30%" }}>Reviews</div>
                      </div>
                      <div className="request-btn">
                      Request not Accepted
                      </div>
                    </div>
                  </div>
                  <div style={{borderBottom: "1px solid rgba(0, 0, 0, 0.2)"}}>
                    <div className="d-flex">
                      <div
                        className="d-flex"
                        style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                      >
                        <div>
                          <SvgImage name="tick" />
                        </div>
                        <div className="fontNM12">DL Verified</div>
                      </div>
                      <div
                        className="d-flex"
                        style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                      >
                        <div>
                          <SvgImage name="tick" />
                        </div>
                        <div className="fontNM12">DL Verified</div>
                      </div>
                      <div
                        className="d-flex"
                        style={{ gap: "5px", padding: "0 7px 3px 7px" }}
                      >
                        <div>
                          <SvgImage name="tick" />
                        </div>
                        <div className="fontNM12">DL Verified</div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>


        {driverSearchList?.length > 0 && <div className="addCustcenter" style={{margin : "10px 10px 0 0"}}>
            <button
              className="greenButton Medium text3 AddCustStartBtn"
              style={{ padding: "0 20px 0 20px" }}
              // onClick={toggleCreateCust}
            >
              <div className="AddCust">{false ?                        <ClipLoader
                            color={"#2E7C7B"}
                            loading={true}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                          /> : "Check All Requests"}</div>
            </button>
          </div>}
        </div>

      </>
      {/* )} */}
    </div>
  );
}

export default SearchHistoryList;
