import {
  TOAST_STATUS,
  TOAST_STATUS_SUCCESS,
  GET_CITIES,
GET_CITIES_SUCCESS
} from "../storeTypes";

const INIT_STATE = {
  loader: false,
  cities : [],
  toastObject: {
    toastStatus: false,
    message: "",
    status: true,
    link : "",
    invoice : false,
    pincode : {},
    handleClose : () => null,
    toggleModal : () => null,
  },
  
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case TOAST_STATUS: {
      console.log("toast reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case TOAST_STATUS_SUCCESS: {
      console.log("toast reducer", action?.payload);
      return {
        ...state,
        loader: false,
        // uploadType: "",

        toastObject: {
          toastStatus: action.payload.toastStatus,
          message: action.payload.message,
          status: action.payload.status,
          link : action.payload?.link ? action.payload?.link : "",
          invoice : action.payload?.invoice ? action.payload?.invoice : false,
          callBack : action.payload?.callBack ? action.payload?.callBack : ()=>{},
          rediractionLink : action.payload?.rediractionLink ? action.payload?.rediractionLink : '',
          mobileNumber : action.payload?.mobileNumber ? action.payload?.mobileNumber : '',
          emailId : action.payload?.emailId ? action.payload?.emailId : '',
          handleClose : action.payload?.handleClose,
          toggleModal : action.payload?.toggleModal
        },
      };
    }

    case GET_CITIES: {
      return {
        ...state,
        loader: true,
      };
    }
    case GET_CITIES_SUCCESS: {
      return {
        ...state,
        loader: false,
        cities : action?.payload?.data?.cities
      };
    }
    default: {
      return state;
    }
  }
};
