import {

  SEND_OTP,
  SEND_OTP_SUCCESS,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,

  REQUEST_LOGIN_SUCCESS,

} from "../storeTypes";
const INIT_STATE = {
  authErrors: {},
  otpStatus: null,
  loader: false,
  authUser: null,
  token: null,
  ownerAccountDetails: {},
  ownerDetails: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {



    case REQUEST_LOGIN_SUCCESS: {
      console.log("AUTHUSER", action?.payload);
      return {
        ...state,
        loader: false,
        otpStatus: action?.payload?.token ? 1 : null,
        authUser: action?.payload,
        token: action?.payload?.token || null,
      };
    }

    case SEND_OTP: {
      console.log("otp-> reducer");
      return {
        ...state,
        loader: true,
      };
    }
    case SEND_OTP_SUCCESS: {
      return {
        ...state,
        otpStatus: 1,
        loader: false,
      };
    }


    case VERIFY_OTP: {
      return {
        ...state,
        loader: true,
      };
    }
    case VERIFY_OTP_SUCCESS: {
      console.log("AUTH USER", action?.payload?.data?.token);
      return {
        ...state,
        loader: false,
      };
    }



    // case CLEAR_AUTH_STORE: {
    //   return {
    //     ...state,
    //     authErrors: {},
    //     loader: false,
    //     authUser: null,
    //     ownerDetails: null,
    //     token: null,
    //     ownerAccountDetails: null,
    //     pinVerify: false,
    //   };
    // }

    default: {
      return state;
    }
  }
};
