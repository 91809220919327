import React from "react";
import "./index.css";
import SvgImage from "../../Core/Icons";
import { useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function VehicleDetails(props) {
  const {vrn} = props;

  
  const {vehicleDetails} = useSelector(state => state?.trip)
  const navigate = useNavigate()

  return (
    <div className="">
      <div
        className="d-flex flex-column"
        // style={{ margin: "50px 0 0 0", gap: "10px" }}
      >
        <div className="d-flex btm0 txnDetalis-1  ">
          <div className="col-lg-6">
            <div className="det-head">Fastag</div>
            {/* <div className="descrip">Last Transaction on 11 Sep 2024</div> */}
          </div>

          <div className="col-lg-6">
            <button
              className="purpleButton"
              style={{ wordBreak: "break-word" }}
              onClick={() => navigate("/CheckFASTagTrnxs",{state : { vrn : vrn }})}
            >
              Check Transactions
            </button>
          </div>
        </div>

        <div className="d-flex btm0 txnDetalis-1  ">
          <div className="col-lg-6">
            <div className="det-head">Challans</div>
            {/* <div className="descrip" style={{ marginLeft: "-70px" }}>
              No Pending Challans
            </div> */}
          </div>

          <div className="col-lg-6">
            <button
              className="purpleButton"
              style={{ wordBreak: "break-word" }}
              onClick={() => navigate("/PendingChallans",{state : { vrn : vrn }})}

            >
              Check Challans
            </button>
          </div>
        </div>

        <div className="d-flex btm0 txnDetalis-1  " style={{paddingBottom : "8px"}}>
          <div className="d-flex flex-column">
            <div className="det-head">Insurance</div>
            <div className="descrip" style={{ marginLeft: "20px" }}>
              {vehicleDetails?.insuranceUpto ? "Expires on " + moment(vehicleDetails?.insuranceUpto).format("DD-MM-YYYY") : ""}
            </div>
          </div>

          {/* <div className="col-lg-6">
            <button
              className="purpleButton"
              style={{ wordBreak: "break-word" }}
            >
              Get Quotes
            </button>
          </div> */}
        </div>
      </div>

      <div className="d-flex btm0 txnDetalis-1  "  style={{paddingBottom : "8px"}}>
      <div className="d-flex flex-column">
      <div className="det-head">PUC</div>
          <div className="descrip" style={{ marginLeft: "20px" }}>
          {vehicleDetails?.pucExpiryDate ? "Expires on " + moment(vehicleDetails?.pucExpiryDate).format("DD-MM-YYYY") : ""}

          </div>
        </div>
      </div>

      <div className="d-flex btm0 txnDetalis-1  "  style={{paddingBottom : "8px"}}>
      <div className="d-flex flex-column">
      <div className="det-head">Road Tax</div>
      <div className="descrip" style={{ marginLeft: "20px" }}>
        {("Expires on " + vehicleDetails?.roadTaxUpto )|| ""}
          </div>
        </div>
      </div>

      <div className="d-flex btm0 txnDetalis-1 "  style={{paddingBottom : "8px"}}>
      <div className="d-flex flex-column">
          <div className="det-head ">Permit</div>
          <div className="descrip" style={{ marginLeft: "20px" }}>
           {vehicleDetails?.permitUpto ? "Expires on " + moment(vehicleDetails?.permitUpto).format("DD-MM-YYYY") : ""}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleDetails;
