import {SEARCH_DRIVER,SEARCH_DRIVER_SUCCESS,SEARCH_DRIVER_HISTORY,SEARCH_DRIVER_HISTORY_SUCCESS,CLEAN_SEARCH_DRIVER_HISTORY } from "../storeTypes";


export const searchDriver = (payload,callBack) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: SEARCH_DRIVER,
    payload,
    callBack
  };
};

export const searchDriverSuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: SEARCH_DRIVER_SUCCESS,
    payload: data,
  };
};

export const searchDriverHistory = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: SEARCH_DRIVER_HISTORY,
    payload,
  };
};

export const searchDriverHistorySuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: SEARCH_DRIVER_HISTORY_SUCCESS,
    payload: data,
  };
};

export const cleanSearchDriverHistory = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: CLEAN_SEARCH_DRIVER_HISTORY,
  };
};