import { call, put, takeEvery } from "redux-saga/effects";
import { getCitiesApi, searchDriverApi, searchDriverHistoryApi } from "../../Utils/Apis";
// import { processErrorAndRespond } from "../../Utils/functions";

import { SEARCH_DRIVER,SEARCH_DRIVER_HISTORY } from "../storeTypes";
// import { Mixpanel } from "../../../Mixpanel";
import {  } from "../actions/appActions";
import { searchDriverHistorySuccess, searchDriverSuccess } from "../actions/driverAction";


function* searchDriverSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(searchDriverApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(searchDriverSuccess(response?.data));
      action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      action.callBack({
        message: error?.response?.data.message,
      });
    } else {
      action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

function* searchDriverHistorySaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(searchDriverHistoryApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(searchDriverHistorySuccess(response?.data));
      // action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      // action.callBack({
      //   message: error?.response?.data.message,
      // });
    } else {
      // action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}


export function* watchDriverSaga() {
  yield takeEvery(SEARCH_DRIVER, searchDriverSaga);
  yield takeEvery(SEARCH_DRIVER_HISTORY, searchDriverHistorySaga);

  
}
