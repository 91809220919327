import { call, put, takeEvery } from "redux-saga/effects";
import { getCitiesApi } from "../../Utils/Apis";
// import { processErrorAndRespond } from "../../Utils/functions";

import { TOAST_STATUS,GET_CITIES } from "../storeTypes";
// import { Mixpanel } from "../../../Mixpanel";
import { getCitiesSuccess, toastStatusSuccess } from "../actions/appActions";

function* toastStatusSaga(action) {
  try {
    console.log("toast saga", action.payload);
    //console.log("yahan", action.payload);
    // const response = yield call(storeMediaFileApi, action.payload);
    // if (response.type == "success") {
    yield put(toastStatusSuccess(action.payload));
    // action.callBack(response.data);
    //ToastAndroid.show('Your media has been uploaded', ToastAndroid.SHORT);
  } catch (error) {
    console.log("Error :- ", error?.response?.data);
    // console.log("API Error:- ", processErrorAndRespond(error));
  }
}

function* getCitiesSaga(action) {
  try {
    console.log("ppppppppppppppp", action);
    const response = yield call(getCitiesApi, action?.payload);
    console.log("Login Resposne", response);
    if (response?.status == 200) {
      yield put(getCitiesSuccess(response?.data));
      // action.callBack(response);
    }
  } catch (error) {
    if (error?.response?.status == 400) {
      // action.callBack({
      //   message: error?.response?.data.message,
      // });
    } else {
      // action.callBack(error?.response?.data);
    }
    console.log("err.stack", error.stack);
    console.log("sendOtpSaga Error.Response:- ", JSON.stringify(error));
    // console.log('API Error:- ', processErrorAndRespond(error));
  }
}

export function* appSaga() {
  yield takeEvery(TOAST_STATUS, toastStatusSaga);
  yield takeEvery(GET_CITIES, getCitiesSaga);

  
}
