import { TOAST_STATUS, TOAST_STATUS_SUCCESS,
  GET_CITIES,
  GET_CITIES_SUCCESS } from "../storeTypes";

export const toastStatus = (payload) => {
    console.log("toastNNJNJNaction", payload);
    return {
      type: TOAST_STATUS,
      payload,
    };
  };
  
  export const toastStatusSuccess = (data) => {
    console.log("toast action success", data);
    return {
      type: TOAST_STATUS_SUCCESS,
      payload: data,
    };
  };


export const getCities = (payload) => {
  console.log("toastNNJNJNaction", payload);
  return {
    type: GET_CITIES,
    payload,
  };
};

export const getCitiesSuccess = (data) => {
  console.log("toast action success", data);
  return {
    type: GET_CITIES_SUCCESS,
    payload: data,
  };
};