import React, { useEffect } from "react";
import Header from "../../SubComponents/Header";
import "./index.css";
import SvgImage from "../../Core/Icons";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFastagTxn } from "../../store/actions/TripsAction";

function CheckFASTagTrnxs(props) {

  const { vrn } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();

  const { fastagTxn } = useSelector((state) => state?.trip);
  console.log("jsrfjr", fastagTxn?.data?.response?.[0]?.response?.vehicle?.vehltxnList?.txn);
 
  
  useEffect(() => {
    console.log("sjrofojrs");
    if (state?.vrn) {
      dispatch(getFastagTxn({ vrn: state?.vrn }, (resp) => {}));
    }
  }, []);

  return (
    <div>
      <Header />
      <div className="">
        <div
          className="d-flex flex-column"
          style={{ margin: "50px 0 0 0", gap: "10px" }}
        >
          <div className="FontNB24">FASTag Transactions</div>
          {/* <div className="box328">
            <div className="FontNB24 fastagBox">{state?.vrn}</div>
            <div className="FontNB24 fastagBoxDesc">
              34161FA82033E764D849B4A0 <br></br>Car/Truck/Jeep
            </div>

          </div> */}
          {fastagTxn?.data == false ? <div>OOPS... SERVER DOWN</div> : !fastagTxn?.data?.response ? (
                    <div style={{margin : "50px 0 0 0"}}>
                    <SvgImage name = "AppIconLoading" />
              
                    </div>

      ) : (
        <>
          {" "}
          <div className="txnDetalis" style={{ marginTop: "30px" }}>
            {(fastagTxn?.data?.response?.[0]?.response?.vehicle?.vehltxnList?.txn?.length||0) > 0 &&
              fastagTxn?.data?.response?.[0]?.response?.vehicle?.vehltxnList?.txn?.sort((a,b)=>a?.readerReadTime>b?.readerReadTime?-1:1)?.map(
                (item, idx) => {
                  return (
                    <div className="d-flex box328" style={{justifyContent : "space-between",height : "100%",    border: "1px solid rgba(108, 108, 108, 0.24)"}}>
                      <div  style={{width : "100%"}}>
                        <div className="fontNM18">{item?.tollPlazaName}</div>
                        <div className="fontNM18" style={{  color: "#6c6c6c"}}>
                          {item?.readerReadTime}
                        </div>
                      </div>
                        {/* <div className="font_20" style={{ fontWeight: "bold" }}>
                          ₹ {item?.fine_imposed}
                        </div> */}
                        {/* <div
                          className="paidbtn font_20 col-lg-6"
                          style={{
                            marginRight: "10px",
                            float: "right",
                            marginTop: "10px",
                            borderRadius: "8px",
                          }}
                          onClick={() => {
                            if (item?.challan_status == "Pending") {
                              navigator.clipboard
                                .writeText(item?.challan_no)
                                .then(() => {
                                  window.open(
                                    "https://echallan.parivahan.gov.in/index/accused-challan",
                                    "_blank"
                                  );
                                })
                                .catch((err) => {
                                  console.error(
                                    "Failed to copy challan number:",
                                    err
                                  );
                                });
                            }
                          }}
                        >
                          <div
                            className="paidbtnFont"
                            style={{
                              background:
                                item?.challan_status == "Pending"
                                  ? "black"
                                  : "3eb655",
                              cursor:
                                item?.challan_status == "Pending"
                                  ? "pointer"
                                  : "",
                            }}
                          >
                            {item?.challan_status == "Pending"
                              ? "Pay Now"
                              : "Paid"}
                          </div>
                        </div> */}
                    </div>
                  );
                }
              )}
          </div>

        </>
      )}
        </div>
      </div>
    </div>
  );
}

export default CheckFASTagTrnxs;
